import { surveyService } from '@services';
import { STEP_LOTTERY, HTTP_CODE_OK, SURVEY_STATUS_INTERVAL } from '@constants';

export default {
  data() {
    return {
      surveyKey: null,
      surveyLink: null,
      iframeWidth: '375px',
      iframeHeight: window.innerHeight + 'px'
    };
  },
  methods: {
    // Call check survey status by survey key
    async checkSurveyStatus() {
      await surveyService.getSurveyStatus({
        key: this.surveyKey,
      }).then((res) => {
        const data = res.data;

        if (data && res.status == HTTP_CODE_OK &&
          data.step == STEP_LOTTERY
        ) {
          this.$router.push({
            name: "lottery.game",
            params: {
              campaignUrlName: data.campaign_url_name,
              lotteryUuid: data.lottery_uuid,
            },
          }).catch(() => {});
        }
      });
    },
  },
  mounted() {
    // Stop page redirect loading
    this.$store.dispatch('stopPageRedirectLoading');
  },
  created() {
    // Get survey key and link from params
    let surveyKey = this.$route?.params?.survey_key;
    let surveyLink = this.$route?.params?.survey_link;

    // If not have key, redirect to the error page
    if (!surveyKey || !surveyLink) {
      return this.$router.push({
        name: 'campaign.error',
        replace: true
      })
    }

    // Set survey key and link
    this.surveyKey = surveyKey;
    this.surveyLink = surveyLink;

    // Set interval 5s to check survey status
    setInterval(() => {
      this.checkSurveyStatus();
    }, SURVEY_STATUS_INTERVAL);
  }
};
